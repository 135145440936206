import { SenecaResponse } from "atfcore-commonclasses";
import { IApplicationScope } from "./applicationScope";

module MainApp {
    var app = angular.module("app");

    app.run(($rootScope: ng.IScope, $state: angular.ui.IStateService) => {
        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            // Al cambio di $state, attivo il loader
            $rootScope.stateIsLoading = true;
        })
        $rootScope.$on('$stateChangeSuccess', function () {
            // Quando ho finito di caricare lo $state, nascondo il loader
            $rootScope.stateIsLoading = false;
        })
    })

    export class MainAppController {
        ctrl: MainAppController;

        static $inject = ["$state", "$providerInjector", "$scope", "$rootScope", "$translate", "tmhDynamicLocale", "amMoment", "moment", "$sessionStorage", "GlobalApplicationData", "jwtHelper", "$uibModal", "$timeout", "UserService", "$location", "$q"];

        constructor(
            protected $state: angular.ui.IStateService,
            protected $providerInjector: any,
            protected $scope: IApplicationScope,
            protected $rootScope: ng.IScope,
            protected $translate: angular.translate.ITranslateService,
            protected tmhDynamicLocale: any,
            protected amMoment: any,
            protected moment: any,
            protected $sessionStorage: any,
            protected GlobalApplicationData: any,
            protected jwtHelper: any,
            protected $uibModal: angular.ui.bootstrap.IModalService,
            protected $timeout: angular.ITimeoutService,
            protected UserService: any,
            protected $location: angular.ILocationService,
            protected $q: angular.IQService,
            protected $mdDateLocaleProvider: any
        ) {
            this.$scope.setJWT = this.setJWT;
            this.$scope.cleanJWT = this.cleanJWT;
            this.$scope.setLocale = this.setLocale;
            this.$scope.openStandardErrorDialog = this.openStandardErrorDialog;

            // Lingua corrente e timezone recuperata dal Token
            this.$scope.geti18nLang = this.geti18nLang;
            this.$scope.getUserTimezone = this.getUserTimezone;

            // Lingua di default
            this.$scope.geti18nDefaultLang = this.geti18nDefaultLang;

            let self = this;
            let cleanUpErrorModalEvent = this.$rootScope.$on('showApplicationModalErrors', (event: any, args: any) => {
                self.$scope.openStandardErrorDialog(args);
            });

            this.$scope.$on('$destroy', () => {
                cleanUpErrorModalEvent();
            });

            // Scrolla la pagina finchè l'elemento non è in testa
            this.$scope.scrollElementToTop = this.scrollElementToTop;

            // Se c'è ancora il preloader dell'intera applicazione, lo rimuovo
            let loader: any = document.getElementById('app-loading');
            if (loader) {
                // Non è possibile utilizzare il remove() di JQuery in quanto non supportato da Internet Explorer
                loader.parentNode.removeChild(loader)
            }
        }

        // Salva il Token in locale e nell'oggetto principale dell'applicativo
        public setJWT = (token: string, forceNow?: boolean, reloadState?: boolean): ng.IPromise<any> => {
            this.$sessionStorage.identityToken = token;
            let promisedResult = this.$q.defer();
            // Il token che mi viene passato potrebbe essere il token vero oppure un tiny token, quindi chiedo sempre la versione estesa (JWT)
            this.UserService.getJWTToken.query({
            }).$promise
                .then((data: SenecaResponse<string>) => {
                    if (data.error || !data.response) {
                        let errors: Array<any> = [];
                        errors.push({ severity: "danger", message: this.$translate.instant("error.generic.SESSION_EXPIRED") });
                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                        // Cancello anche il JWT eventualmente già salvato
                        this.cleanJWT();
                        promisedResult.resolve();
                    } else {
                        this.GlobalApplicationData.jwtPayload = this.jwtHelper.decodeToken(data.response);

                        // Se esiste un timer precedente per il rinnovo del token, lo annullo
                        if (this.GlobalApplicationData.tokenRenewalTimer) {
                            this.$timeout.cancel(this.GlobalApplicationData.tokenRenewalTimer);
                        }
                        this.GlobalApplicationData.tokenRenewalTimer = this.$timeout(() => {
                            this.UserService.renewToken.query({
                            })
                                .$promise
                                .then((data: SenecaResponse<string>) => {
                                    // Se non ho il Token segnalo un errore
                                    if (data.error) {
                                        // Non faccio niente. Il token resta quello di prima
                                    } else {
                                        // Aggiorno il token nella sessione locale del browser
                                        this.$scope.setJWT(data.response);
                                    }
                                })
                        }, forceNow ? 0 : this.GlobalApplicationData.tokenRenewalMillis);
                        // Recupero i gruppi dell'utente loggato, dato che non sono più inclusi all'interno del token jwt
                        this.UserService.getGroupsOfUser.get({})
                            .$promise
                            .then((groupsResponse: SenecaResponse<any>) => {
                                if (groupsResponse.error) {
                                    // Non faccio niente. Tengo i gruppi recuperati precedentemente
                                } else {
                                    // Salvo i dati dei gruppi in sessione
                                    this.GlobalApplicationData.userGroups = groupsResponse.response;
                                }
                                if (reloadState) {
                                    this.$state.reload();
                                }
                                promisedResult.resolve();
                            })
                    }
                })
                .catch((error: any) => {
                    let errors: Array<any> = [];
                    errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                    this.$rootScope.$emit("showApplicationModalErrors", errors);
                    promisedResult.resolve();
                    // Cancello anche il JWT eventualmente già salvato
                    this.cleanJWT();
                });
            return promisedResult.promise;
        };

        // Elimina il Token
        public cleanJWT = () => {
            this.$sessionStorage.identityToken = null;
            this.GlobalApplicationData.jwtPayload = null;
            this.GlobalApplicationData.userGroups = null;
            // Se esiste un timer precedente per il rinnovo del token, lo annullo
            if (this.GlobalApplicationData.tokenRenewalTimer) {
                this.$timeout.cancel(this.GlobalApplicationData.tokenRenewalTimer);
            }
        }

        public updateDateFormatter = ($provider: any, moment: any, format: string) => {
            $provider.formatDate = (value: any) => {
                let date = moment(value);
                if (date.isValid()) {
                    return date.format(format);
                }
                return '';
            };
        };

        // Gestisce il cambio lingua
        public setLocale = (langKey: string, localeKey: string) => {
            // Se mi sono arrivati i dati li aggiorno
            if (langKey) {
                // Metodo del plugin che si occupa si impostare la lingua passata
                this.$translate.use(langKey);

                // Uso il servizio specifico anche per caricare il file standard di angular del locale
                this.tmhDynamicLocale.set(langKey);

                // Imposto anche il locale ed il timezone per la libreria moment.js, in modo che le date create con moment(data) siano localizzate correttamente
                this.amMoment.changeLocale(langKey);
                this.moment.tz.setDefault(localeKey);

                let $mdDateLocaleProvider = this.$providerInjector.get('$mdDateLocaleProvider');
                let format = this.$translate.instant('generic.DATE_FORMAT');

                this.updateDateFormatter($mdDateLocaleProvider, this.moment, format);
            }
        };

        // Scrolla l'elemento fino in testa alla pagina
        public scrollElementToTop = ($event: any) => {
            if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
                $(window).scrollTop($($event.currentTarget).offset().top);
            }
        }

        /**
         * Questa finestra modale si aspetta che con l'evento sia passato un array composto da
         * oggetti con due attributi: 'severity' e 'code' più un terzo opzionale 'message'
         * 'severity' può essere: 'success', 'info', 'warning', 'danger'.
         * 'code' è il numero dell'eccezione che sarà associata alla traduzione prefissata da "sqlMessage."
         * 'message' è un opzionale testo aggiuntivo che arriva dal server e deve essere già decodificato in lingua.
         * 'hideUnknown' è un opzionale booleano che nasconde il testo di errore sconosciuto nel caso si veglia mostrare solamente il testo aggiuntivo
         * Es:
         var errors = [];
        errors.push({severity: 'success', code: 0, message: 'Testo di success'});
        errors.push({severity: 'info', code: 21351, message: 'Testo di info'});
        errors.push({severity: 'warning', code: 222, message: 'Testo di warning'});
        errors.push({severity: 'danger', code: 3135, message: 'Testo di errore'});
        $rootScope.$emit('showApplicationModalErrors', errors);
        */
        public openStandardErrorDialog = (errors: any) => {
            let self = this;
            let modalInstance = this.$uibModal.open({
                backdrop: "static",
                size: 'md',
                templateUrl: 'errors-modal.html',
                controller: 'ErrorsModalInstanceController as ctrl',
                resolve: {
                    errors: () => {
                        return errors;
                    },
                    $translate: () => {
                        return this.$translate;
                    }
                }

            });

            // Ritorno la promessa della risposta dell'utente
            return modalInstance.result;
        }

        // Lingua attualmente in uso recuperata dal Token
        public geti18nLang = () => {
            return this.GlobalApplicationData.jwtPayload && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user.userOptions.langCode ? this.GlobalApplicationData.jwtPayload.user.userOptions.langCode.substring(0, 2) : "it";
        }

        // Lingua attualmente in uso recuperata dal Token
        public getUserTimezone = () => {
            return this.GlobalApplicationData.jwtPayload && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user.userOptions.timezone ? this.GlobalApplicationData.jwtPayload.user.userOptions.timezone : "Europe/Rome";
        }

        // La lingua di default
        public geti18nDefaultLang = () => {
            for (var i = 0; i < this.GlobalApplicationData.langs.length; i++) {
                if (this.GlobalApplicationData.langs[i].mandatory) {
                    return this.GlobalApplicationData.langs[i].lang.substring(0, 2);
                }
            }
            return "it";
        }

    }
    app.controller("MainAppController", MainApp.MainAppController);
}

/**
 * Controller della modale per gli errori applicativi
 */

module ErrorsModalInstance {
    var app = angular.module("app");

    export interface IErrorsModalInstanceControllerScope extends ng.IScope {
        ok: Function;
        errors: any;
    }

    export class ErrorsModalInstanceController {
        ctrl: ErrorsModalInstanceController;

        static $inject = ["$scope", "$uibModalInstance", "$translate", "errors"];

        constructor(protected $scope: IErrorsModalInstanceControllerScope,
            protected $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            protected $translate: angular.translate.ITranslateService,
            protected errors: any
        ) {
            $scope.errors = errors;

            this.$scope.ok = this.ok;

            angular.forEach($scope.errors, (e) => {
                if (e.code) {
                    e.decoded = $translate.instant('sqlMessages.' + e.code);
                    if (e.decoded === 'sqlMessages.' + e.code) {
                        e.decoded = $translate.instant('sqlMessages.UNEXPECTED_ERROR') + " (cod. " + e.code + ")";
                    }
                }
                else {
                    if (!e.hideUnknown) {
                        e.decoded = $translate.instant('sqlMessages.UNKNON_ERROR');
                    }
                }
            });
        }

        public ok = () => {
            this.$uibModalInstance.close();
        }

    }
    app.controller("ErrorsModalInstanceController", ErrorsModalInstance.ErrorsModalInstanceController);
}